<template>
  <div>
    <div class="handle-box">
      <el-input v-model="pagination.searchKey" placeholder="违禁词" class="handle-input mrb10" clearable></el-input>
      <el-button type="primary" icon="el-icon-search" @click="searchForbiddenWord()">搜索</el-button>
      <el-button type="success" @click="openDialog(null,0)">添加</el-button>
    </div>
    <el-table :data="photoList" border class="table" header-cell-class-name="table-header">
      <el-table-column fixed="right" header-align="center" align="center" min-width="150" label="操作">
        <template slot-scope="scope" >
          <!--          <el-button type="text" size="small" v-if="scope.row.allocation === 0" @click="useExpenseUser(scope.row)">分配公司</el-button>-->
          <!--          <el-button type="text" style="color:#1d953f" size="small" v-if="scope.row.allocation === 1" >已分配</el-button>-->
          <el-button type="text" size="small" @click="openDialog(scope.row,1)">编辑</el-button>
          <el-button type="text" size="small" @click="deleteHandle(scope.row.id)" >删除</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="ID" width="55" align="center"></el-table-column>
      <el-table-column prop="url" label="地址" align="center"></el-table-column>
      <el-table-column prop="min" label="最小值" align="center"></el-table-column>
      <el-table-column prop="max" label="最大值" align="center"></el-table-column>
      <el-table-column prop="priority" label="优先级" align="center"></el-table-column>
      <el-table-column prop="type" label="类型" align="center"></el-table-column>
      <el-table-column prop="remark" label="备注" align="center"></el-table-column>
      <!--        <el-table-column prop="phoneNumber" label="手机号" align="center"></el-table-column>-->
      <!--        <el-table-column prop="email" label="邮箱" align="center"></el-table-column>-->


    </el-table>
    <el-dialog title="添加图片地址"
               :visible.sync="photoDialog"
               width="30%"
               :before-close="handleClose"
               :append-to-body="true"
               destroy-on-close
               center>
      <div class="my-dialog">


        <el-input placeholder="请输入url" v-model="photoWall.url">
          <template slot="prepend">url地址</template>
        </el-input>
        <el-input placeholder="请输入最小值"  type="number" v-model="photoWall.min">
          <template slot="prepend">最小值</template>
        </el-input>
        <el-input placeholder="请输入最大值"  type="number" v-model="photoWall.max">
          <template slot="prepend">最大值</template>
        </el-input>
        <el-input type="number" placeholder="请输入整数，数字小的在前面"
                  v-model="photoWall.priority">
          <template slot="prepend">分类优先级</template>
        </el-input>
        <el-input placeholder="请输入备注" v-model="photoWall.remark">
          <template slot="prepend">备注</template>
        </el-input>
        <div style="text-align: center">图片后缀</div>
        <div class="myCenter">
          <el-radio-group v-model="photoWall.type">
            <el-radio-button :label="'.jpg'">jpg</el-radio-button>
            <el-radio-button :label="'.png'">png</el-radio-button>
            <el-radio-button :label="'.jpeg'">jpeg</el-radio-button>
          </el-radio-group>
        </div>
        <div  style="text-align: center"> or</div>
        <el-input placeholder="请输入文件后缀" v-model="photoWall.type" >
          <template slot="prepend">自定义后缀</template>
        </el-input>
      </div>

      <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose()">取 消</el-button>
          <el-button type="primary" @click="saveSortEdit()">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      photoDialog: false,
      photoList: [],
      sort: {},
      photoWall: {
        id: null,
        url: "",
        type: '.jpg',
        priority: null,
        max: null,
        min: null,
        remark: "",
      },
      pagination: {
        current: 1,
        size: 10,
        total: 0,
        searchKey: "",
        recommendStatus: null,
        sortId: null,
        labelId: null
      },

    }
  },

  computed: {},

  watch: {},

  created() {
    this.getPhotoWallInfo();
  },

  mounted() {

  },

  methods: {
    openDialog(row,type) {
      this.photoDialog = true
      this.type = type
      if (row!==null){
        this.photoWall = row
      }
      // this.addOrUpdateProWords(row,type)
    },
    searchForbiddenWord(){
      // 搜索禁用词
      this.$http
        .post("/photoWall/list", this.pagination)
        .then(res => {
          if (res.code === 200) {
            this.photoList = res.data.records;
            this.pagination.total = res.data.total;
          }
          else {
            this.$message.error(res.data.msg);
          }
        });
    },
    deleteHandle(id) {
      let url;
      url = "/photoWall/delete";
      this.$confirm('确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        center: true
      }).then(() => {
        this.$http.get(this.$constant.baseURL + url, {id: id}, true)
          .then((res) => {
            this.$message({
              message: "删除成功！",
              type: "success"
            });
            this.getPhotoWallInfo();
            this.sort = {};
          })
          .catch((error) => {
            this.$message({
              message: error.message,
              type: "error"
            });
          });
      }).catch(() => {
        this.$message({
          type: 'success',
          message: '已取消删除!'
        });
      });
    },
    saveSortEdit() {
      if (this.$common.isEmpty(this.photoWall.type) ||
        this.$common.isEmpty(this.photoWall.priority) ||
        this.$common.isEmpty(this.photoWall.remark) ||
        this.$common.isEmpty(this.photoWall.url) ||
        this.$common.isEmpty(this.photoWall.max) ||
        this.$common.isEmpty(this.photoWall.min)) {
        this.$message({
          message: "请完善所有分类信息！",
          type: "error"
        });
        return;
      }
      debugger
      let url;
      if (this.type === 0) {
        url = "/photoWall/add";
      } else {
        url = "/photoWall/update";
      }
      this.$http.post(this.$constant.baseURL + url, this.photoWall, true)
        .then((res) => {
          if (res.msg=='执行成功'){
            this.$message({
              message: "保存成功！",
              type: "success"
            });
          }

          this.getPhotoWallInfo();
          this.handleClose();
        })
        .catch((error) => {
          this.$message({
            message: error.message,
            type: "error"
          });
        });
    },
    handleClose() {

      this.photoWall={
        id: null,
          url: "",
          type: 1,
          priority: null,
          max: null,
          min: null,
          remark: "",
      },

      this.photoDialog = false;
    },
    getPhotoWallInfo() {
      this.$http.get("/photoWall/selectAll",this.pagination).then(res => {
          if (res.code === 200) {
            this.photoList = res.data.records;
            this.pagination.total = res.data.total;
          }
          else {
            this.$message.error(res.data.msg);
          }
        }).catch((error) => {
          this.$message({
            message: error.message,
            type: "error"
          });
        });
    }
  }
}
</script>

<style scoped>

.handle-box {
  margin-bottom: 20px;
}
.my-dialog > div {
  margin: 12px;
}

.my-dialog >>> input::-webkit-inner-spin-button {
  appearance: none;
}
</style>
